<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <template>
            <b-button
              class="flex-shrink-0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
            >
              Create
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        :rows="rows"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        styleClass="vgt-table striped"
      >
        <template slot="table-row" slot-scope="props">
          <!-- status -->
          <template v-if="props?.column?.field === 'format_name'">
            <b-badge
              pill
              :style="{
                backgroundColor: props?.row?.color_code,
              }"
            >
              {{ props?.row?.name }}
            </b-badge>
          </template>
          <span v-if="props.column.field === 'format_status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props?.row?.status ? "Active" : "Inactive" }}
            </b-badge>
          </span>
          <template v-if="props?.column?.field === 'format_type'">
            <template v-if="props?.row?.type == 1">
              <b-badge variant="light-warning">General</b-badge>
            </template>
            <template v-else-if="props?.row?.type == 2">
              <b-badge variant="light-success">Success</b-badge>
            </template>
            <template v-else-if="props?.row?.type == 3">
              <b-badge variant="light-danger">Dead</b-badge>
            </template>
          </template>
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <template
                  v-if="$permissionAbility(LEAD_STATUS_EDIT, permissions)"
                >
                  <b-dropdown-item v-on:click="onShow(props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>
                </template>

                <template
                  v-if="$permissionAbility(LEAD_STATUS_DELETE, permissions)"
                >
                  <b-dropdown-item v-on:click="onDelete(props.row.id)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </template>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
      id="modal-status-form"
      centered
      :title="modelType == 'editModel' ? 'Edit Status' : 'Create Status'"
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
    >
      <validation-observer ref="leadStatusValidation">
        <b-form v-on:submit.prevent="validationForm">
          <!-- name -->
          <b-form-group label="Name" label-for="name">
            <validation-provider
              #default="{ errors }"
              name="name"
              vid="name"
              rules="required|max:255"
            >
              <b-form-input
                id="name"
                type="text"
                v-model="name"
                :state="errors.length > 0 ? false : null"
                placeholder="Status name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Sequence-->
          <b-form-group label="Sequence" label-for="sequence">
            <ValidationProvider
              name="sequence"
              v-slot="{ errors }"
              vid="sequence"
            >
              <v-select
                @open="filteredLeadSequenceStatus"
                option-disabled="disable"
                id="sequence"
                placeholder="Choose Sequence"
                v-model="selectSequence"
                :options="leadSequenceOptions"
                :reduce="(option) => option.value"
                label="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- select status color-->
          <b-form-group label="Status Color" label-for="color_name">
            <ValidationProvider
              name="color_name"
              v-slot="{ errors }"
              vid="color_name"
            >
              <v-select
                id="color_code"
                placeholder="Choose Color Code"
                v-model="selectColorValue"
                :options="selectColorOption"
                label="name"
              >
                <template #option="{ name, value }">
                  <b-badge pill :style="{ background: value }">{{
                    name
                  }}</b-badge>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- select status type -->
          <b-form-group label="Status Type" label-for="type">
            <ValidationProvider
              name="status type"
              v-slot="{ errors }"
              vid="type"
            >
              <v-select
                id="type"
                placeholder="Choose Status Type"
                v-model="selectStatusTypeValue"
                :options="leadStatusTypeConstants"
                :reduce="(option) => option.value"
                label="name"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- select status type-->
          <b-form-group label="Status" label-for="status">
            <ValidationProvider name="status" v-slot="{ errors }" vid="status">
              <v-select
                id="status"
                placeholder="Choose Status"
                v-model="selectStatusValue"
                :options="statusValueOption"
                :reduce="(option) => option.value"
                label="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isLeadStatusFormSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max, min } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  LEAD_STATUS_ACCESS,
  LEAD_STATUS_CREATE,
  LEAD_STATUS_EDIT,
  LEAD_STATUS_DELETE,
} from "@/helpers/permissionsConstant";
import LvColorPicker from "lightvue/color-picker";
import { leadSequenceConstants } from "@/helpers/constant/leadSequenceConstant";
import { leadStatusTypeConstants } from "@/helpers/constant/leadStatusTypeConstant";

export default {
  name: "LeadStatusView",
  components: {
    LvColorPicker,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      LEAD_STATUS_ACCESS,
      LEAD_STATUS_CREATE,
      LEAD_STATUS_EDIT,
      LEAD_STATUS_DELETE,
      selectSequence: "",
      leadSequenceOptions: [],
      selectStatusTypeValue: "",
      leadStatusTypeConstants,
      leadStatusId: "",
      modelType: "",
      name: "",
      selectColor: "",
      selectColorCode: "",
      selectColorValue: "",
      countDead: "",
      countSuccess: "",
      selectColorOption: [
        {
          name: "Light Orange",
          value: "#E08C3E",
        },
        {
          name: "Dark Orange",
          value: " #E0600B",
        },
        {
          name: "Light Green",
          value: "#28C76F",
        },
        {
          name: "Dark Green",
          value: "#007A37",
        },
        {
          name: "Light Blue",
          value: "#00cfe8",
        },
        {
          name: "Dark Blue",
          value: "#203792",
        },
        {
          name: "Light Purple",
          value: "#7367f0 ",
        },
        {
          name: "Dark Purple",
          value: "#6E2092",
        },
        {
          name: "Light Grey",
          value: "#82868B",
        },
        {
          name: "Dark Grey",
          value: "#525252",
        },
        {
          name: "Light Red",
          value: "#E22222",
        },
        {
          name: "Dark Red",
          value: "#9E0000",
        },
      ],
      selectStatusValue: "",
      statusValueOption: [
        {
          name: "Active",
          value: true,
        },
        {
          name: "Inactive",
          value: false,
        },
      ],

      pageLength: 10,
      columns: [
        {
          label: "Name",
          field: "format_name",
          sortable: false,
        },
        {
          label: "Phase",
          field: "sequence",
          sortable: false,
        },
        {
          label: "Lead Counts",
          field: "leads_count",
          sortable: false,
        },
        {
          label: "Color Name",
          field: "color_name",
          sortable: false,
        },
        {
          label: "Color Code",
          field: "color_code",
          sortable: false,
        },
        {
          label: "Status",
          field: "format_status",
          sortable: false,
        },
        {
          label: "Type",
          field: "format_type",
          formatFn: this.formatFnTablePosition,
          sortable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      isLeadStatusFormSubmitLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
    statusVariant() {
      const statusColor = {
        true: "light-success",
        false: "light-danger",
      };
      return (status) => statusColor[status];
    },
  },
  async created() {
    try {
      this.loadItems();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },
  methods: {
    formatFnTableStatus(status) {
      return status ? "Active" : "Inactive";
    },
    formatFnTablePosition(final) {
      return final ? "Final" : "Not Final";
    },
    showModal() {
      this.$bvModal.show("modal-status-form");
    },
    hiddenModal() {
      this.$bvModal.hide("modal-status-form");
      this.resetModal();
    },
    resetModal() {
      this.modelType = "";
      this.name = "";
      this.selectSequence = "";
      this.selectColorValue = "";
      this.leadStatusId = "";
      this.selectStatusValue = "";
      this.selectStatusTypeValue = "";
    },
    onShow(value) {
      this.modelType = "editModel";
      this.leadStatusId = value?.id;
      this.name = value?.name;
      this.selectSequence = value?.sequence;
      this.selectColorValue = {
        name: value?.color_name,
        value: value?.color_code,
      };
      this.selectStatusTypeValue = value?.type;
      this.selectStatusValue = value?.status;
      this.showModal();
    },

    async onDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete This?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/lead-status/${id}`);
            this.loadItems();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Lead Status successfully deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    async getStatusItems() {
      return await this.$api.get("api/lead-status");
    },
    async getStatusTypeCount() {
      return await this.$api.get("api/lead-status/type-count");
    },

    async loadItems() {
      try {
        const [leadStatus, statusTypeCount] = await Promise.all([
          this.getStatusItems(),
          this.getStatusTypeCount(),
        ]);

        this.countDead = statusTypeCount?.data?.data?.countDead;
        this.countSuccess = statusTypeCount?.data?.data?.countSuccess;
        const data = leadStatus?.data?.data;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async updateLeadStatus() {
      this.isLeadStatusFormSubmitLoading = true;
      await this.$api.put(`/api/lead-status/${this.leadStatusId}`, {
        name: this.name,
        color_name: this.selectColorValue.name,
        color_code: this.selectColorValue.value,
        status: this.selectStatusValue,
        sequence: this.selectSequence,
        type: this.selectStatusTypeValue,
      });
      this.isLeadStatusFormSubmitLoading = false;
      this.loadItems();
      this.hiddenModal();
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Success",
          icon: "BellIcon",
          variant: "success",
          text: "Lead Status successfully updated",
        },
      });
    },
    validationForm: async function () {
      this.$refs.leadStatusValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.$refs.leadStatusValidation.reset();
            if (this.modelType == "editModel") {
              if (
                this.selectStatusTypeValue == 2 ||
                this.selectStatusTypeValue == 3
              ) {
                let message = "";
                if (this.selectStatusTypeValue == 2) {
                  message = "Success";
                } else {
                  message = "Dead";
                }
                this.$swal({
                  title: "Warning!",
                  text: `This Lead Status Will Be Set As ${message}!`,
                  icon: "warning",
                  customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                  },
                  buttonsStyling: false,
                  showCancelButton: true,
                  confirmButtonText: "Ok",
                  showLoaderOnConfirm: true,
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    this.updateLeadStatus();
                  }
                });

                this.isLeadStatusFormSubmitLoading = false;
              } else {
                this.updateLeadStatus();
              }
            } else {
              this.isLeadStatusFormSubmitLoading = true;

              if (
                (this.selectStatusTypeValue == 3 && this.countDead >= 1) ||
                (this.selectStatusTypeValue == 2 && this.countSuccess >= 1)
              ) {
                let message = "";

                if (this.selectStatusTypeValue == 3) {
                  message = "Dead";
                } else if (this.selectStatusTypeValue == 2) {
                  message = "Success";
                }
                this.$swal({
                  title: "Error!",
                  html: `Status Type <b>'${message}'</b> Can't be Multiple`,
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                  },
                  buttonsStyling: false,
                });
                this.isLeadStatusFormSubmitLoading = false;
              } else {
                await this.$api.post("/api/lead-status", {
                  name: this.name,
                  color_name: this.selectColorValue.name,
                  sequence: this.selectSequence,
                  color_code: this.selectColorValue.value,
                  status: this.selectStatusValue,
                  type: this.selectStatusTypeValue,
                });
                this.isLeadStatusFormSubmitLoading = false;
                this.hiddenModal();
                this.loadItems();
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Lead Status successfully created",
                  },
                });
              }
            }
          } catch (error) {
            this.isLeadStatusFormSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }
            if (error?.response?.data?.errors) {
              this.$refs.leadStatusValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },

    async filteredLeadSequenceStatus() {
      try {
        const { data } = await this.$api.get("/api/lead-sequences");
        const filteredStatus = leadSequenceConstants.filter(
          (item) => !data?.data?.includes(item.value)
        );
        this.leadSequenceOptions = filteredStatus;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>





